import axios from "@/plugins/axios";

const CANDIDATES = "candidates";
const RHR_CANDIDATE = "rhr/candidates";
const CANDIDATE_EDIT = "candidates/update";
const ADD_COMMENT = "candidates/add-comment";
const CALENDAR_ADD = "rhr/calendars";
const RHR = "rhr/users";
const UPDATE_ONBOARD = "rhr/candidates/update-onboard";
const APPROACH_HISTORY = '/approach-history';
const GET_APRROACH_HISTORY = '/approach-history/candidate';
const COMMUNICATIONS = "/communication-by-source"
const EDIT_HISTORY = "/update-history/candidate"
const GET_CANDIDATES_EDIT = "candidates/edit";
const SHARE_PUBLIC = "share-link";
const AUTH_SHARE_PUBLIC = "share-link/authenticate";
const CHECK_AUTH_PUBLIC = "share-link/check-auth";
const CANDIDATE_PUBLIC = "share-link/candidate";
const STATUS_CANDIDATES ="job-process/change/process/status";
const GET_PROCESS_STATUS ="job-process/change/process";
const ARCHIVE_CANDIDATES = "job-process/storage/process";
const GET_PROCESS_CANDIDATE = "job-process/detail/process";

export async function listCandidates(params) {
  try {
    const { data } = await axios.get(`${CANDIDATES}`, { params });

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getCandidate(payload) {
  try {
    const { data } = await axios.get(`${CANDIDATES}/${payload}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getProcessCandidate(payload) {
  try {
    const res = await axios.get(`${GET_PROCESS_CANDIDATE}/${payload}`);

    return res;
  } catch (error) {
    return { error };
  }
}

export async function getEditCandidate(payload) {
  try {
    const { data } = await axios.get(`${GET_CANDIDATES_EDIT}/${payload}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function listRHR() {
  try {
    const { data } = await axios.get(`${RHR}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function updateStatus(body) {
  try {
    const { data } = await axios.post(`${STATUS_CANDIDATES}`, body);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getStatus(body) {
  try {
    const { data } = await axios.get(`${GET_PROCESS_STATUS}`, {
      params: body
    });

    return data;
  } catch (error) {
    return { error };
  }
}

export async function addCandidate(payload) {
  try {
    let formData = new FormData();
    if (payload.candidate_name) {
      formData.append("candidate_name", payload.candidate_name);
    }
    if (payload.birthday) {
      formData.append("birthday", payload.birthday);
    }
    if (payload.gender) {
      formData.append("gender", payload.gender);
    }
    if (payload.address) {
      formData.append("address", payload.address);
    }
    if (payload.current_company) {
      formData.append("current_company", payload.current_company);
    }
    if (payload.position_id) {
      formData.append("position_id", payload.position_id);
    }
    if (payload.level_id) {
      formData.append("level_id", payload.level_id);
    }
    if (payload.phone) {
      formData.append("phone", payload.phone);
    }
    if (payload.email) {
      formData.append("email", payload.email);
    }
    if (payload.facebook) {
      formData.append("facebook", payload.facebook);
    }
    if (payload.x) {
      formData.append("x", payload.x);
    }
    if (payload.skype) {
      formData.append("skype", payload.skype);
    }
    if (payload.linkedin) {
      formData.append("linkedin", payload.linkedin);
    }
    if (payload.telegram) {
      formData.append("telegram", payload.telegram);
    }
    if (payload.skill_ids) {
      formData.append("skill_ids", payload.skill_ids);
    }
    if (payload.other_skills) {
      formData.append("other_skills", payload.other_skills);
    }
    if (payload.certificate_ids) {
      formData.append("certificate_ids", payload.certificate_ids);
    }
    if (payload.other_certificates) {
      formData.append("other_certificates", payload.other_certificates);
    }
    if (payload.language_certificate_ids) {
      formData.append("language_certificate_ids", payload.language_certificate_ids);
    }
    if (payload.school) {
      formData.append("school", payload.school);
    }
    if (payload.education_id) {
      formData.append("education_id", payload.education_id);
    }
    if (payload.experiences) {
      formData.append("experiences", payload.experiences);
    }
    if (payload.source_id) {
      formData.append("source_id", payload.source_id);
    }
    if (payload.communication_id) {
      formData.append("communication_id", payload.communication_id);
    }
    if (payload.introduce) {
      formData.append("introduce", payload.introduce);
    }
    if (payload.assign_user_id) {
      formData.append("assign_user_id", payload.assign_user_id);
    }
    if (payload.cv_file_name) {
      formData.append("cv_file_name", payload.cv_file_name);
    }
    if (payload.onboard_date) {
      formData.append("onboard_date", payload.onboard_date);
    }
    if (payload.salary_unit) {
      formData.append("salary_unit", payload.salary_unit);
    }
    if (payload.min_salary) {
      formData.append("min_salary", payload.min_salary);
    }
    if (payload.max_salary) {
      formData.append("max_salary", payload.max_salary);
    }

    for (let i = 0; i < payload.cv.length; i++) {
      formData.append("cv[]", payload.cv[i]);
    }

    const { data } = await axios.post(`${CANDIDATES}`, formData);
    return data;
  } catch (error) {
    return { error };
  }
}

export async function deleteCandidate(params) {
  try {
    const { data } = await axios.post(`${ARCHIVE_CANDIDATES}/${params}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function addCalendar(payload) {
  try {
    const { data } = await axios.post(`${CALENDAR_ADD}`, payload);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function addComment(params, body) {
  try {
    const { data } = await axios.post(`${ADD_COMMENT}/${params}`, body);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function updateOnBoard(params, body) {
  try {
    const { data } = await axios.post(`${UPDATE_ONBOARD}/${params}`, body);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function assignOwn(params, body) {
  try {
    const { data } = await axios.post(
      `${RHR_CANDIDATE}/${params}/assign`,
      body
    );

    return data;
  } catch (error) {
    return { error };
  }
}

export async function updateCandidate(payload) {
  try {
    let formData = new FormData();
    if (payload.candidate_name) {
      formData.append("candidate_name", payload.candidate_name);
    }
    formData.append("birthday", payload.birthday);
    if (payload.gender) {
      formData.append("gender", payload.gender);
    }
    formData.append("address", payload.address);
    formData.append("current_company", payload.current_company);
    formData.append("position_id", payload.position_id);
    formData.append("level_id", payload.level_id);
    formData.append("phone", payload.phone);
    formData.append("email", payload.email);
    formData.append("facebook", payload.facebook);
    formData.append("x", payload.x);
    formData.append("skype", payload.skype);
    formData.append("linkedin", payload.linkedin);
    formData.append("telegram", payload.telegram);
    formData.append("skill_ids", payload.skill_ids);
    formData.append("other_skills", payload.other_skills);
    formData.append("certificate_ids", payload.certificate_ids);
    formData.append("other_certificates", payload.other_certificates);
    formData.append("language_certificate_ids", payload.language_certificate_ids);
    formData.append("school", payload.school);
    formData.append("education_id", payload.education_id);
    formData.append("experiences", payload.experiences);
    formData.append("source_id", payload.source_id);
    formData.append("communication_id", payload.communication_id);
    formData.append("introduce", payload.introduce);
    formData.append("assign_user_id", payload.assign_user_id);
    formData.append("cv_file_name", payload.cv_file_name);
    formData.append("onboard_date", payload.onboard_date);
    if (payload.salary_unit) {
      formData.append("salary_unit", payload.salary_unit);
    }
    formData.append("min_salary", payload.min_salary);
    formData.append("max_salary", payload.max_salary);
    if (payload.last_updated) {
      formData.append("last_updated", payload.last_updated);
    }

    for (let i = 0; i < payload.cv.length; i++) {
      formData.append(`cv[${i}]`, payload.cv[i]);
    }

    formData.append("candidate_id", payload.candidate_id);

    const { data } = await axios.post(`${CANDIDATE_EDIT}`, formData);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function addApproachHistory(params) {
  try {
    let formData = new FormData();
    formData.append("approach_time", params.approach_time);
    formData.append("communication_id", params.communication_id);
    formData.append("user_id", params.user_id);
    formData.append("candidate_id", params.candidate_id);
    formData.append("content", params.content);
    formData.append("image", params.image);

    const { data } = await axios.post(`${APPROACH_HISTORY}`, formData);

    return data;
  } catch(error) {
    return { error };
  }
}

export async function getCandidateAproachHistory(params) {
  try {
    const { data } = await axios.get(`${GET_APRROACH_HISTORY}/${params}`);

    return data;
  } catch(error) {
    return { error };
  }
}
    
export async function getCommunicationsBySource(payload) {
  try {
    const { data } = await axios.get(`${COMMUNICATIONS}?source_id=${payload}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getEditHistories(params) {
  try {
    const { data } = await axios.get(`${EDIT_HISTORY}/${params}`);

    return data;
  } catch(error) {
    return { error };
  }
}

export async function sharePublic(body) {
  try {
    const { data } = await axios.post(`${SHARE_PUBLIC}`, body);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function authSharePublic(body) {
  try {
    const { data } = await axios.post(`${AUTH_SHARE_PUBLIC}`, body);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function checkAuthPublic(params) {
  try {
    const { data } = await axios.get(`${CHECK_AUTH_PUBLIC}`, { params });

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getCandidatePublic(id, token) {
  try {
    const { data } = await axios.get(`${CANDIDATE_PUBLIC}/${id}?token=${token}`, );

    return data;
  } catch (error) {
    return { error };
  }
}
