import { infoMail, sendMail } from "@/apis/mail";

export const state = {
  list_mails: [],
  response_post: {},
};

export const getters = {
  list_mails: (state) => state.list_mails,
  response_post: (state) => state.response_post,
};

export const mutations = {
  LISTMAILS(state, data) {    
    state.list_mails = data.map(item => {
      return{ 
        ...item,
        id: item.template_id,
        name: item.template_title,
      }
    })
  },
  RESPONSE(state, data) {
    state.response_post = data
  },
};

export const actions = {
  async infoMail({ commit }, params) {
    const res = await infoMail(params);
    if (res?.error) {
      return false;
    }
    commit("LISTMAILS", res);
    return true;
  },
  async sendMail({ commit }, payload) {
    const res = await sendMail(payload);
    console.log("res", res);
    if (res.error) {
      return res;
    }
    commit("RESPONSE", res);
    return true;
  },
};
