import { listJobs, removeJobCandidates, updateStatus, getListCandidate, addCandidateForJob, listJobProcess, changeProcess, filterJobProcess, getListTask, updateTask, deleteTask } from "@/apis/rhr";

export const state = {
  list_jobs: {},
  response: {},
  response_status: {},
  listCandidates: {},
  listProcess: {},
  processStatus: {},
  addCandidate: {},
  dataCandidate: {},
  listTask: {},
  errorAdd: {},
};

export const getters = {
  list_jobs: (state) => state.list_jobs,
  response: (state) => state.response,
  response_status: (state) => state.response_status,
  taskStatus: (state) => state.taskStatus,
  processStatus: (state) => state.processStatus,
  listCandidates: (state) => state.listCandidates,
  listProcess: (state) => state.listProcess,
  dataCandidate: (state) => state.dataCandidate,
  addCandidate: (state) => state.addCandidate,
  errorAdd: (state) => state.errorAdd,
  listTask: (state) => state.listTask,
  deleteTask: (state) => state.deleteTask,
};

export const mutations = {
  LISTJOBS(state, data) {
    state.list_jobs = data;
  },

  LISTPROCESS(state, data) {
    state.listProcess = data;
  },

  RESPONSE(state, data) {
    state.response = data;
  },

  RESPONSE_STATUS(state, data) {
    state.response_status = data;
  },

  RESPONSE_UPDATE_TASK(state, data) {
    state.taskStatus = data;
  },

  RESPONSE_DELETE_TASK(state, data) {
    state.deleteTask = data;
  },

  PROCESS_STATUS(state, data) {
    state.processStatus = data;
  },

  UPDATE_PROCESS(state, data) {
    state.dataCandidate = data
  },

  GETLISTCANDIDATE(state, data) {
    state.listCandidates = data;
  },

  GETLISTTASK(state, data) {
    state.listTask = data;
  },

  ADDCANDIDATEFORJOB(state, data) {
    state.addCandidate = data;
  },

  ERRORADD(state, data) {
    state.errorAdd = data.error.location;
  },
};

export const actions = {
  async listJobs({ commit }, params) {
    const res = await listJobs(params);
    if (res.error) {
      return false;
    }
    commit("LISTJOBS", res);
    return true;
  },

  async updateStatus({ commit }, payload) {
    const res = await updateStatus(payload.params, payload.body);
    if (res?.error) {
      return false;
    }
    commit("RESPONSE_STATUS", res);
    return true;
  },

  async updateTask({ commit }, data) {
    const res = await updateTask(data.params, data.body);
    if (res?.error) {
      return false;
    }
    commit("RESPONSE_UPDATE_TASK", res);
    return true;
  },

  async deleteTask({ commit }, data) {
    const res = await deleteTask(data.params, data.body);
    if (res?.error) {
      return false;
    }
    commit("RESPONSE_DELETE_TASK", res);
    return true;
  },

  async changeProcess({ commit }, payload) {
    const res = await changeProcess(payload.params, payload.body);
    if (res?.error) {
      return false;
    }
    commit("PROCESS_STATUS", res);
  },

  async updateProcess({ commit }, payload) {
    const res = await changeProcess(payload.params, payload.body);
    if (res?.error) {
      return false;
    }
    commit("UPDATE_PROCESS", res);
    return true;
  },

  async removeJobCandidates({ commit }, payload) {
    const res = await removeJobCandidates(payload.job_id, {params: {'candidate_id': payload.candidate_id}});
    if (res.error) {
      return false;
    }
    commit("RESPONSE", res);
    return true;
  },

  async listJobProcess({ commit }, job_id) {
    const res = await listJobProcess(job_id);
    if (res?.error) {
      return false;
    }
    commit("LISTPROCESS", res);
    return true;
  },

  async filterJobProcess({ commit }, payload) {
    const res = await filterJobProcess(payload.param, payload.body);
    if (res?.error) {
      return false;
    }
    commit("LISTPROCESS", res);
    return true;
  },  

  async getListCandidate({ commit }, params) {
    const res = await getListCandidate(params);
    if (res?.error) {
      return false;
    }
    commit("GETLISTCANDIDATE", res);
    return true;
  },

  async addCandidateForJob({ commit }, payload) {
    const res = await addCandidateForJob(payload.param, payload.body);
    if (res?.error) {
      commit("ERRORADD", res);
      return false;
    }
    commit("ADDCANDIDATEFORJOB", res);
    return true;
  },

  async getListTask({ commit }, params) {
    const res = await getListTask(params);
    if (res?.error) {
      return false;
    }
    commit("GETLISTTASK", res);
    return true;
  },
};
