import axios from "@/plugins/axios";

const LISTMAILS = "/mail/info-mail";
const SENDMAIL_CANDIDATE = "/mail/send-mail";

export async function infoMail(params) {
  try {
    const { data } = await axios.get(`${LISTMAILS}?${params}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function sendMail(payload) {
  try {
    console.log("payload", payload);
    let formData = new FormData();

    formData.append("mail_to", payload.mail_to);
    formData.append("mail_cc", payload.mail_cc);
    formData.append("subject", payload.subject);
    formData.append("message", payload.message);
    formData.append("job_process_task_map_id", payload.job_process_task_map_id);
    formData.append("job_id", payload.job_id);
    formData.append("job_process_id", payload.job_process_id);
    formData.append("process_status_id", payload.process_status_id);
    formData.append("template_id", payload.template_id); 

    for (let i = 0; i < payload.attachment.length; i++) {
      formData.append(`attachment[${i}]`, payload.cv[i]);
    }

    // formData.append("job_id", payload.job_id);

    const { data } = await axios.post(`${SENDMAIL_CANDIDATE}`, formData);

    return data;
  } catch (error) {
    return { error };
  }
}
